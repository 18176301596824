<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.50135 15.1344L9.50138 15.1343L15.6646 8.97112C14.8258 8.622 13.8323 8.04852 12.8927 7.10892C11.9529 6.16917 11.3794 5.1755 11.0303 4.3366L4.86699 10.4999L4.86697 10.4999C4.38603 10.9809 4.14553 11.2214 3.93872 11.4865C3.69475 11.7993 3.48558 12.1378 3.31492 12.4959C3.17025 12.7994 3.0627 13.1221 2.8476 13.7674L1.71334 17.1701C1.60749 17.4877 1.69014 17.8378 1.92683 18.0745C2.16352 18.3112 2.51362 18.3938 2.83117 18.288L6.23395 17.1537C6.87923 16.9386 7.20188 16.8311 7.50545 16.6864C7.86354 16.5157 8.20198 16.3066 8.51477 16.0626C8.77992 15.8558 9.02043 15.6153 9.50135 15.1344Z"
      fill="currentColor"
    />
    <path
      d="M17.3748 7.2609C18.6546 5.98115 18.6546 3.90626 17.3748 2.6265C16.0951 1.34675 14.0202 1.34675 12.7404 2.6265L12.0012 3.3657C12.0113 3.39627 12.0218 3.42726 12.0327 3.45865C12.3037 4.2396 12.8149 5.26336 13.7766 6.22504C14.7382 7.18671 15.762 7.69792 16.543 7.96887C16.5742 7.97971 16.605 7.99016 16.6355 8.00024L17.3748 7.2609Z"
      fill="currentColor"
    />
  </svg>
</template>
