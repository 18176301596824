<template>
  <div class="flex h-full flex-col items-stretch gap-2 pt-2">
    <!-- <ConfirmDialog
      message="Если изменить адрес, то меню может измениться, хотите поменять?"
      :show="showConfirmDialog"
      @confirmed="onConfirmSelection()"
      @rejected="onRejectedSelection()"
      @close="showConfirmDialog = false"
    /> -->
    <div class="relative h-0 grow">
      <HeadlessRadioGroup
        v-model="selectedRestaurant"
        class="scrollbar-hide flex h-full flex-col items-stretch gap-2 overflow-y-auto scroll-smooth py-2"
        :by="compareRests"
      >
        <HeadlessRadioGroupOption
          v-for="restaurant in restaurants"
          :key="restaurant.id"
          v-slot="{ active, checked }"
          as="template"
          :value="restaurant"
        >
          <button
            class="rounded-xl border-2 border-transparent bg-white p-2 shadow-main outline-none transition-colors"
            :class="{
              '!border-orange-200': checked,
              'border-orange-100': active,
            }"
          >
            <div class="mb-1 flex">
              <IconMapPoint class="mr-2 w-5" />
              <div>
                {{ restaurant.adres }}
              </div>
            </div>
            <div class="flex text-sm">
              <div class="ml-7 opacity-50">
                {{ restaurant.from_hour }}
              </div>
            </div>
          </button>
        </HeadlessRadioGroupOption>
      </HeadlessRadioGroup>

      <div class="absolute left-0 right-0 top-0 h-2 bg-gradient-to-b from-whitegray to-transparent"></div>
      <div class="absolute bottom-0 left-0 right-0 h-2 bg-gradient-to-t from-whitegray to-transparent"></div>
    </div>

    <SimpleButton
      class="px-4 py-4 font-medium uppercase"
      type="button"
      :disabled="!selectedRestaurant"
      @click="onConfirmSelection"
    >
      Выбрать
    </SimpleButton>
  </div>
</template>

<script setup lang="ts">
const emit = defineEmits(['updateCoords', 'close', 'locationChanged'])

const { data: restaurants } = useRestaurants((v) => v)

const selectedRestaurant = ref<IRestaurant>()

const compareRests = (a?: IRestaurant, b?: IRestaurant) => {
  return a?.id === b?.id
}

watchEffect(() => {
  if (selectedRestaurant.value) {
    emit('updateCoords', [selectedRestaurant.value.lng, selectedRestaurant.value.lat])
  }
})

const { data: currentReceptionWay } = useCurrentReceptionWay()
watch(
  [currentReceptionWay],
  () => {
    if (currentReceptionWay.value && currentReceptionWay.value.type === 'restaurant') {
      selectedRestaurant.value = currentReceptionWay.value
    }
  },
  {
    immediate: true,
  }
)

const setCurrentReceptionWay = useSetCurrentReceptionWay()

const selectRestaurant = () => {
  if (selectedRestaurant.value) {
    setCurrentReceptionWay({
      type: 'restaurant',
      ...selectedRestaurant.value,
    })
    emit('close')
  }
}

const showConfirmDialog = ref(false)
const onConfirmSelection = () => {
  showConfirmDialog.value = false
  emit('locationChanged')
  selectRestaurant()
}
const onRejectedSelection = () => {
  showConfirmDialog.value = false
}
</script>
